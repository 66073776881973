import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import PopupSlider from './PopupSlider'
import './slider.scss'

class MediaSlider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showPopup: false,
      direction: 'forward',
      currentSlide: 0,
    }
    this.wrapper = React.createRef()
    this.togglePopup = this.togglePopup.bind(this)
  }

  togglePopup() {
    console.log(this.wrapper) //eslint-disable-line
    const row = this.wrapper.current.closest('.vc_row')
    this.setState({ showPopup: !this.state.showPopup })
    if (!this.state.showPopup) {
      row.classList.add('openmodal')
    } else {
      row.classList.remove('openmodal')
    }
  }

  render() {
    const { slides, autoplay, playspeed } = this.props
    const { showPopup, currentSlide } = this.state

    const settings = {
      fade: true,
      dots: true,
      speed: 500,
      autoplay: autoplay === 'yes' ? true : false,
      infinite: autoplay === 'yes' ? true : false,
      autoplaySpeed: playspeed * 1000,

      draggable: true,
      beforeChange: (c, n) =>
        this.setState({
          currentSlide: n,
          direction:
            n == slides.length - 1 && c === 0
              ? 'back'
              : (c == slides.length - 1 && n === 0) || c < n
              ? 'forward'
              : 'back',
        }),
    }

    return (
      <div
        ref={this.wrapper}
        className={
          'animated-slider ' + this.state.direction + ' slides-' + slides.length
        }
      >
        <Slider {...settings}>
          {slides.map((slide, i) => (
            <div className="item" key={i}>
              <div
                className="item__media-holder parallax_image"
                data-speed="0.05"
              >
                <div
                  className="item__media-holder lazyload"
                  data-bg={slide.imageBg}
                >
                  {slide.slideType == 'video' && (
                    <div className="play-link" onClick={this.togglePopup} />
                  )}
                  {slide.slideType == 'ytvideo' && (
                    <div className="play-link" onClick={this.togglePopup} />
                  )}
                </div>
              </div>
              {slide.caption ? (
                <div className="item__caption">
                  {slide.caption.split(' ').map((item, key) => {
                    return (
                      <span key={key} className="item__caption-letter">
                        {item}
                      </span>
                    )
                  })}
                </div>
              ) : (
                ''
              )}
            </div>
          ))}
        </Slider>
        {showPopup ? (
          <PopupSlider
            slides={slides}
            initialSlide={currentSlide}
            togglePopup={this.togglePopup}
          />
        ) : (
          ''
        )}
      </div>
    )
  }
}

MediaSlider.propTypes = {
  slides: PropTypes.array,
  autoplay: PropTypes.string,
  playspeed: PropTypes.string,
}

export default MediaSlider
