import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import './slider.scss'

class PopupSlider extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { slides, initialSlide, togglePopup } = this.props
    const settings = {
      fade: true,
      dots: true,
      speed: 2000,
      draggable: false,
      initialSlide: initialSlide,
      beforeChange: (currentIndex, nextIndex) => {
        let currentSlideVideo = document.querySelector(
          '.slick-slide.item--popup[data-index="' + currentIndex + '"] video'
        )
        if (currentSlideVideo) {
          currentSlideVideo.pause()
        }
        let nextSlideVideo = document.querySelector(
          '.slick-slide.item--popup[data-index="' + nextIndex + '"] video'
        )
        if (nextSlideVideo) {
          setTimeout(() => {
            nextSlideVideo.play()
          }, 1000)
        }
      },
    }
    return (
      <div className="popup-slider" style={{ animation: `fadeIn 1s` }}>
        <div className="popup-slider__close" onClick={togglePopup}>
          <i className="la la-close" />
        </div>
        <Slider {...settings}>
          {slides.map((slide, i) => (
            <div
              className={
                'item item--popup popup-slider-type-' + slide.slideType
              }
              key={i}
            >
              {slide.slideType == 'image' && (
                <div
                  className="item__media-holder lazyload"
                  data-bg={slide.imageBg}
                />
              )}
              {slide.slideType == 'video' && (
                <div
                  className="item__media-holder lazyload"
                  data-bg={slide.videoPoster}
                >
                  {React.createElement('video', {
                    className: 'video',
                    src: slide.video,
                    type: 'video/mp4',
                    autoPlay: i == initialSlide ? true : false,
                    controls: true,
                  })}
                </div>
              )}
              {slide.slideType == 'ytvideo' && (
                <div
                  className="item__media-holder lazyload"
                  data-bg={slide.videoPoster}
                >
                  <iframe
                    className="video"
                    src={'https://www.youtube.com/embed/' + slide.ytvideoid}
                    title="YouTube video player"
                    frameBorder="0"
                    allowFullScreen
                  />
                </div>
              )}
            </div>
          ))}
        </Slider>
      </div>
    )
  }
}

PopupSlider.propTypes = {
  slides: PropTypes.array,
  initialSlide: PropTypes.number,
  togglePopup: PropTypes.func,
}

export default PopupSlider
